
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import ClientData from "@/modules/orders/components/ClientData.vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import Payments from "@/modules/orders/components/Payments.vue";
import allowedFields from "../../../config/available-fields.json";
import FileUploader from "@/components/form-components/FileUploader.vue";
import CommonActions from "@/modules/orders/mixins/common_actions";
import Client from "@/modules/orders/mixins/client";
import Comments from "@/modules/orders/mixins/comments";
import Permissions from "@/modules/orders/mixins/permissions";
import Services from "@/modules/orders/mixins/services";
import DocumentType from "@/modules/orders/mixins/documentType";
import OrderServices from "@/modules/orders/components/OrderServices.vue";
// import Totals from "@/modules/orders/components/order_types/CERT/Totals.vue";
import CommentsPreview from "@/modules/orders/components/CommentsPreview.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import certGoods from "@/modules/orders/mixins/certGoods";
import OrderActions from "@/modules/orders/components/OrderActions.vue";
import orderActionsMixin from "@/modules/orders/mixins/orderActionsMixin";
import MultipleLiveSearch from "@/components/form-components/MultipleLiveSearch.vue";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    MultipleLiveSearch,
    OrderActions,
    LiveSearch,
    CommentsPreview,
    // Totals,
    OrderServices,
    Payments,
    ClientData,
    FileUploader
  },

  mixins: [
    CommonActions,
    Services,
    Client,
    Comments,
    Permissions,
    DocumentType,
    orderActionsMixin,
    certGoods
  ],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentStatusAlias: {
      type: String,
      required: true,
      default: ""
    }
  },

  directives: { mask },

  data: () => ({
    paymentsTotalPrice: 0 as number,
    allowedFields: allowedFields as any,
    rules,
    selectedField: "" as string,
    loading: false as boolean,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      order: {
        source: "client",
        client: { type: "" } as any,
        power_of_attorney: "beneficiary" as string,
        use_last_power_of_attorney: true as boolean
      } as any,
      details: { goods: [] as Array<any> } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      weightUnits: [] as Array<SelectComponentInterface>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 0 as number,
    totalSum: 0 as number,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    comments: {} as any,
    oldModel: null as any,
    forceKey: 0 as number,
    regions: [
      { text: "Moldova", value: "moldova" },
      { text: "Transnistria", value: "transnistria" }
    ],
    powerOfAttorney: null as any,
    panel: [0] as Array<number>,
    maxLength: 12,
    maxDocumentCounter: 20
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 0;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }

        if (this.model.hasOwnProperty("parent_documents")) {
          if (!this.isMultipleSelect) {
            const [item] = this.model.parent_documents;
            if (item) {
              this.selectedCard = { ...item };
            }
          }
        }
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    },
    errorMessages() {
      Object.keys(this.errorMessages)
        .filter((key: string) => key.startsWith("details.goods."))
        .map(this.getErrorIndex);
    },
    computedRules(): object {
      return {
        goodsMassValidation: this.goodsMassValidation
      };
    },
    "model.details.goods": {
      immediate: true,
      deep: true,
      handler() {
        this.panel.push(this.model.details.goods.length);
      }
    }
  },

  computed: {
    needCertificationConfirmation() {
      return this.model.order.status.alias === "check_beneficiary";
    },
    isDataChanged() {
      if (this.model && this.oldModel) {
        return JSON.stringify(this.model) !== JSON.stringify(this.oldModel);
      }
      return false;
    },
    canEditOrder() {
      return ["processing", "draft"].includes(this.model.order.status.alias);
    },
    isMultipleSelect() {
      return this.model.order.document_type === "substitution";
    },
    printCertificateLabel() {
      if (this.currentStatusAlias === "issued") {
        return this.$t("orders.buttons.cert.preview_certificate_issued");
      }
      return this.$t("orders.buttons.cert.preview_certificate");
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    goodsMassValidation(index: number): any {
      if (!this.model.details.goods[index].massa_netto) {
        return true;
      }

      return (
        Number(this.model.details.goods[index].massa_netto) <=
          Number(this.model.details.goods[index].massa_brutto) ||
        this.$t("orders.form.fields.net_mass_error_message")
      );
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 0;
        return;
      } else {
        this.selectedTab = 2;
      }
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    async getData() {
      this.$emit("update");
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 2;
      this.loadingCarnetIssused = tabIndex === 3;
    },
    async loadLists(): Promise<void> {
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const [purpose, { items }, countries] = await Promise.all([
          this.$API
            .purposeOfUse()
            .getList(this.model.order.sertice_type_id, headers),
          this.$API.transportTypes().getList(headers),
          this.$API.countries().getList(headers)
        ]);

        this.selects = {
          ...this.selects,
          purpose,
          transportTypes: items,
          countries: countries.items
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const response = await Promise.all([
          this.$API
            .purposeOfUse()
            .getList(this.model.order.sertice_type_id, headers),
          this.$API.orders().getOrderReleaseModes(),
          this.$API.clients().getClientTypes(),
          this.$API.orders().getCardTypes(this.model.order.service_type_id),
          this.$API.orders().getLanguages(),
          this.$API.transportTypes().getList(headers),
          this.$API.goodsCategories().getList(),
          this.$API.countries().getList(headers),
          this.$API.measureUnits().getList({
            service_type_id: this.model.order.service_type_id
          }),
          this.$API.packingCategories().getList(),
          this.$API.measureUnits().getList({
            service_type_id: this.model.order.service_type_id,
            is_weight_unit: true
          })
        ]);

        this.scanned_documents = await API.orders().getScannedDocuments(
          Number(this.$route.params.id)
        );
        this.setDefaultTab();
        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };

          if (
            model.hasOwnProperty("parent_documents") &&
            Array.isArray(model.parent_documents)
          ) {
            model.parent_documents = model.parent_documents.map((item: any) => {
              if (item.hasOwnProperty("value")) {
                return item.value;
              }

              if (item.hasOwnProperty("id")) {
                return item.id;
              }

              return item;
            });
          }

          await this.$API.orders().edit(Number(this.$route.params.id), model);
          form.updateGuard();
          await this.$emit("update");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        } else {
          this.selectedTab = 1;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length) {
            this.selectedTab = 1;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages,
        transportTypes,
        goodsCategories,
        countries,
        units,
        packingCategories,
        weightUnits
      ] = response;

      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.selects.transportTypes = transportTypes.items;
      this.selects.goodsCategories = goodsCategories;
      this.selects.countries = countries.items;
      this.selects.units = units;
      this.selects.weightUnits = weightUnits;
      this.selects.packingCategories = packingCategories;

      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.$forceUpdate();
      });
    },
    changeData(items: Array<any>) {
      this.model.details.countries = items;
    },
    setPurposeDescription() {
      const index = this.selects.purpose.findIndex(
        (item: any) => item.value === this.model.details.purpose_id
      );

      if (index !== -1) {
        this.model.details.purpose_description = this.selects.purpose[
          index
        ].description;
      }
    },
    setBeneficiary(event: any) {
      this.selectedClient = { value: event.id, text: event.name };
      this.setClient(event);
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    loadScannedDocuments(val: any) {
      this.scanned_documents = val;
    },
    async sendScannedDocuments() {
      try {
        await this.$API
          .orders()
          .sendScannedDocuments(
            Number(this.$route.params.id),
            this.scanned_documents
          );
      } catch (e) {
        if (e.message) {
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    },
    addDocument(): void {
      this.model.documents.push({ file_type: "certification" });
    },
    removeDocument(index: number): void {
      this.model.documents.splice(index, 1);
    },
    async downloadDocument(): Promise<void> {
      // window.open(
      //   `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.$route.params.id}/pdf/last_certificate_templates`
      // );
      try {
        const file = await this.$API
          .orders()
          .printAaijOrder(Number(this.$route.params.id));
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async confirmCertificationData(): Promise<void> {
      try {
        await this.$API
          .orders()
          .changeOrderStatus(this.model.order.id, "checked_beneficiary");
        await this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    isNumber(evt: any) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
});
